import { createRouter, createWebHashHistory } from 'vue-router';

const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_ROUTE_BASE_URL),
  routes: [
    {
      path: '/',
      name: 'pcLayout',
      component: () => import('@/layouts/PcLayout.vue'),
      children: [
        {
          path: '/',
          name: 'Gemei',
          component: () => import('@/views/pcLayout/Gemei.vue'),
        },
      ],
    },
    {
      path: '/m',
      name: 'mobileLayout',
      component: () => import('@/layouts/MobileLayout.vue'),
      children: [
        {
          path: '',
          name: 'mobileHome',
          component: () => import('@/views/mobileLayout/Home.vue'),
        },
      ],
    },
  ],
});

export default router;
